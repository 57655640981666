import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"

const StyledMission = styled.div`
	padding-top: 200px;
	padding-bottom: 137px;
	font-size: 18px;
	line-height: 28px;
	display: flex;
	align-items: start;
	.mission__content {
		width: 30%;
		display: flex;
		flex-direction: column;
	}
	.mission__list {
		width: 70%;
		display: flex;
		flex-wrap: wrap;
		justify-content: end;
		padding-left: 40px;
	}

	@media (max-width: ${breakpoint.default.to}px) {
		flex-wrap: wrap;
		flex-direction: column-reverse;
		padding-bottom: 105px;
		.mission__content {
			width: 100%;
			padding-right: 0;
			flex-direction: row;
			margin-top: 40px;
		}
		.mission__list {
			width: 100%;
			justify-content: space-between;
			padding-left: 0;
		}
	}
`

export default StyledMission
