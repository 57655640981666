import React from "react"
import { shape } from "prop-types"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import Card from "../components/Card"
import Mission from "../components/Mission"
import Members from "../components/Members"
import A from "../components/Link"
import BottomArrow from "../components/Card/BottomArrow"
import Partners from "../components/Partners"
import { color } from "../components/_globalSettings/skin"

const IndexPage = ({ pageContext }) => {
	const { headline, content, points } = { ...pageContext.mission }
	return (
		<Layout {...pageContext}>
			<Row>
				<Card
					type="main"
					{...pageContext.banner}
					bottomContent={<BottomArrow text="S čím Vám můžeme pomoci" />}
				>
					<Members
						data={pageContext.members.nodes}
						others={pageContext.otherMembers.nodes.length}
					/>
				</Card>
			</Row>
			<Row color={color.light.n700}>
				<Mission headline={headline} content={content} points={points}>
					<A href={pageContext.stanovy.publicURL} className="card__link">
						Stanovy asociace
					</A>
				</Mission>
			</Row>
			<Row wrap="wrap" color="#FFF">
				<Partners {...pageContext.references} title="Naše reference" />
			</Row>
			<div style={{ height: "5px", width: "100%", background: "#F8F9FC" }} />
		</Layout>
	)
}

IndexPage.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default IndexPage
