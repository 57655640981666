import React from "react"
import styled from "styled-components"
import { arrayOf, string, shape, number } from "prop-types"
import Image from "./Image"
import A from "./Link"
import { color } from "./_globalSettings/skin"
import { breakpoint } from "./_globalSettings/var"
import { typography } from "./_globalSettings/typography"

const StyledMembers = styled.span`
	display: grid;
	grid-template-columns: auto auto auto;
	grid-gap: 1px;
	background-color: ${color.light.n500};
	.link {
		background: #fff;
		justify-content: center;
		align-items: center;
		width: 100%;
		text-align: center;
		display: flex;
		height: 101px;
		color: ${color.main.text2};
		${typography.BodyM};
		& > .gatsby-image-wrapper {
			display: block;
		}
	}
	.link--join {
		${typography.ButtonS};
		color: ${color.main.primary1};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	.empty-box {
		background: #fff;
	}

	@media (max-width: ${breakpoint.default.to}px) {
		grid-template-columns: auto auto;
		.link--more-members {
			display: none;
		}
		.empty-box {
			display: none;
		}
	}
`

const Members = ({ data, others, ...rest }) => (
	<StyledMembers {...rest}>
		{data.map(member => (
			<A
				href={`/clenove/#${member.selfLink}`}
				key={member.selfLink}
				className="link"
			>
				<Image data={member.image} alt={member.selfLink} />
			</A>
		))}
		{others > 0 && (
			<A href="/clenove" className="link" style={{ opacity: "1" }}>
				{`A ${others} dalších členů`}
			</A>
		)}
		<A
			href="https://docs.google.com/forms/d/e/1FAIpQLSekjFdepyHvgasNrnzidE3rVzkyBtDHjG7AQNwL_qkzsYwRAA/viewform"
			className="link link--join"
		>
			Přidej se +
		</A>
		<div className="empty-box" />
	</StyledMembers>
)

Members.propTypes = {
	data: arrayOf(
		shape({
			selfLink: string.isRequired,
			image: shape({
				publicURL: string.isRequired,
			}).isRequired,
		}).isRequired
	).isRequired,
	others: number.isRequired,
}

export default Members
