import React from "react"
import { string, shape, arrayOf, node } from "prop-types"
import StyledMission from "./style"
import Card, { cardProps } from "../Card"

const Mission = ({
	button,
	link,
	image,
	points,
	children,
	headline,
	content,
}) => {
	return (
		<>
			<StyledMission>
				<div className="mission__content">
					<Card
						type="missionReverse"
						image={image}
						link={link}
						button={button}
						headline={headline}
						content={content}
					>
						{children}
					</Card>
				</div>
				<div className="mission__list">
					{points.map(point => {
						return (
							<Card key={point.image.publicURL} type="mission" {...point} />
						)
					})}
				</div>
			</StyledMission>
		</>
	)
}

export const missionProps = {
	headline: string,
	content: string,
	button: shape({
		label: string.isRequired,
		url: string.isRequired,
	}),
	link: shape({
		label: string.isRequired,
		url: string.isRequired,
	}),
	image: shape({
		publicURL: string.isRequired,
	}),
	points: arrayOf(
		shape({
			...cardProps,
		})
	),
	children: node,
}

Mission.propTypes = {
	...missionProps,
}

Mission.defaultProps = {
	link: undefined,
	image: undefined,
	headline: undefined,
	content: undefined,
	children: undefined,
}

export default Mission
